import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    pageButton: {
      marginRight: theme.spacing(1),
    },
  })
);
interface PaginationProps {
  totalItems: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
}
/**
 * 数据分页
 *
 * @param {number} totalItems -总数据量
 * @param {number} pageSize -每页数据量
 * @param {number} currentPage -当前页码
 * @param {(newPage: number) => void} onPageChange -页码改变事件
 * @return {*}  {JSX.Element}
 */
function Pagination({
  totalItems,
  pageSize,
  currentPage,
  onPageChange,
}: PaginationProps): JSX.Element {
  const totalPages = Math.ceil(totalItems / pageSize);
  const classes = useStyles();

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange]);

  const handleNextPage = useCallback(() => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, totalPages, onPageChange]);

  return (
    <div>
      <Button
        className={classes.pageButton}
        type="button"
        variant="contained"
        color="primary"
        size="small"
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
      >
        上一页
      </Button>
      <span className={classes.pageButton}>当前页: {currentPage}</span>
      {/* <span> {totalItems}个数据</span> */}
      <span className={classes.pageButton}> 总页数：{totalPages}</span>
      <Button
        className={classes.pageButton}
        type="button"
        variant="contained"
        color="primary"
        size="small"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        下一页
      </Button>
    </div>
  );
}

export default Pagination;
