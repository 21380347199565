import React from 'react';

import Layout from '../../layout/WhiteListLayout';
import PublicBiddingProjectsList from '../../views/consultant/publicBiddingProjectsListView';
const PublicBiddingProjects = (): React.ReactElement => {
  return (
    <Layout>
      <PublicBiddingProjectsList></PublicBiddingProjectsList>
    </Layout>
  );
};

export default PublicBiddingProjects;
