import React, { ReactElement } from 'react';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core';

import Industry from '../../utils/industry';

const styles = (): StyleRules =>
  createStyles({
    cityContainer: {
      padding: '1rem 0',
    },
    citySelect: {
      paddingLeft: '1rem',
      marginBottom: '2rem',
      color: '#242f65',
    },
    industryList: {
      height: 400,
      overflow: 'auto',
    },
    industryItem: {
      display: 'flex',
      marginBottom: '1rem',
    },
    industryItemLabel: {
      marginRight: '1rem',
      color: '#242f65',
      fontWeight: 'bold',
      width: '6rem',
      padding: '0.5rem',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    industryChildren: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
    },
    industryChild: {
      padding: '0.5rem',
      margin: '0 0.5rem',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      color: '#242f65',
      '&:hover': {
        background: '#9b9bbe',
        color: '#fff',
      },
    },
  });

const ConsultantIndustryView = ({ classes, selectIndustry }: Props): ReactElement => {
  const industryList = Industry.map(item => {
    const label = item.children?.[0]?.label;
    const child = item?.children?.[0]?.children || [];
    return {
      label,
      child,
    };
  });

  return (
    <div className={classes.cityContainer}>
      <div className={classes.industryList}>
        {industryList.map((item, index) => {
          return (
            <div key={index} className={classes.industryItem}>
              <div className={classes.industryItemLabel}> {item.label}:</div>
              <div className={classes.industryChildren}>
                {item.child?.map(child => {
                  return (
                    <div
                      key={child.value}
                      className={classes.industryChild}
                      onClick={() => selectIndustry({ name: child.label, code: child.value })}
                    >
                      {child.label}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  selectIndustry: (val: { name: string; code: string }) => void;
}

export default withStyles(styles)(ConsultantIndustryView);
