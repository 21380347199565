export const Seniority = [
  {
    value: 'L1',
    label: '不限',
  },
  {
    value: 'L2',
    label: '1-3年',
  },
  {
    value: 'L3',
    label: '3-5年',
  },
  {
    value: 'L4',
    label: '5-10年',
  },
  {
    value: 'L5',
    label: '10年以上',
  },
];
export default Seniority;
