import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  StyleRules,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { GridRowData } from '@mui/x-data-grid';
import { Modal, Select, SelectProps, Tag } from 'antd';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import { anonymousGetAllProjectRequest } from '../../actions/projectRequestAction';
import { CancelCompanyPublishProjectDialog } from '../../components/Dialog';
import Pagination from '../../components/pagination';
import picture from '../../images/fintegrity-logo.jpeg';
import { AppState } from '../../reducers';
import { FUserTypeEnum } from '../../reducers/userReducer';
import CITY from '../../utils/city';
import Industry from '../../utils/industry';
import { industryFilterHandle } from '../../utils/industryFilter';
import PROVINCE from '../../utils/province';
import { searchLabelsByValue } from '../../utils/searchUtils';
import Seniority from '../../utils/seniority';
import specialData from '../../utils/special';
import Professional from '../../utils/special';
// import ConsultantAppliesBiddingView from './consultantAppliesBiddingView';
import ConsultantCityListView from './ConsultantCityListView';
import ConsultantIndustryView from './ConsultantIndustryView';
import ConsultantSpecialView from './ConsultantSpecialView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    pagePadding: {
      padding: '2rem 0',
      position: 'relative',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    textTitle: {
      textAlign: 'center',
    },
    container: {
      width: '100%',
      padding: '2rem',
      marginBottom: '2rem',
      background: '#fff',
      boxShadow: '0px 8px 20px 0px rgba(51, 51, 51, 0.1)',
      borderRadius: '1rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    filterItem: {
      display: 'flex',
      color: '#242f65',
      marginBottom: '1rem',
    },
    filterItemContent: {
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiOutlinedInput-input': {
        padding: '6px !important',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '6px !important',
      },
    },
    filterItemLabel: {
      fontWeight: 600,
      padding: '0.5rem 0',
      minWidth: '5rem',
      textAlign: 'right',
    },
    cityItem: {
      padding: '0.5rem',
      margin: '0 0.5rem',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      maxWidth: '10rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:hover': {
        background: '#9b9bbe',
        color: '#fff',
        overflow: 'visible' /* 移入时显示全部文本 */,
        whiteSpace: 'normal' /* 允许文本换行 */,
        textOverflow: 'clip' /* 移除省略符号 */,
      },
    },
    cityItemActive: {
      background: '#9b9bbe',
      color: '#fff',
      transition: 'all 0.3s ease-in-out',
    },
    filterBottom: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    listContainer: {
      padding: '1rem',
      width: '100%',
    },
    listItem: {
      padding: '1rem',
      borderRadius: '1rem',
      background: '#fff',
      marginBottom: '1.25rem',
      position: 'relative',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
    topItem: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      wordWrap: 'break-word',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '300px',
      },
    },

    itemInfo: {
      color: '#242f65',
      fontSize: '1rem',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        width: '35rem',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: '0.25rem',
      },
    },

    itemInfoLine: {
      color: '#242f65',
      fontSize: '0.875rem',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '0.3rem',
      width: '100%',
    },

    infoLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    infoLabelValue: {
      minWidth: '4rem',
      marginLeft: '0.5rem',
    },
    projectName: {
      marginLeft: '0.5rem',
      maxWidth: '12rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    infoValue: {
      [theme.breakpoints.up('sm')]: {
        width: '15rem',
      },
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    bottomItem: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    arrow: {
      width: '2rem',
      height: '2rem',
      color: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #ddd',
      margin: '0 0.25rem',
      cursor: 'pointer',
      '&:hover': {
        color: '#242f65',
      },
    },
    pages: {
      display: 'flex',
      alignItems: 'center',
    },
    pageItem: {
      width: '2rem',
      height: '2rem',
      border: '1px solid #ddd',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 0.25rem',
      cursor: 'pointer',
      '&:hover': {
        background: '#242f65',
        color: '#fff',
      },
    },
    salaryInput: {
      marginRight: '1rem',
    },
    empty: {
      height: 'calc(100vh - 300px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      margin: '1.25rem  0',
      color: '#242f65',
      fontSize: '2rem',
      borderRadius: '2rem',
    },
    searchContainer: {
      position: 'fixed',
      top: '50%',
      right: '1%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      zIndex: 999,
      '& .MuiInputBase-input': {
        padding: '6px !important',
      },
    },
    search: {
      cursor: 'pointer',
      width: '3rem',
      height: '3rem',
      color: '#242f65',
      background: '#fff',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '1rem',
    },
    paginationRight: {
      textAlign: 'right',
      paddingRight: '2rem',
    },
    textTag: {
      margin: '0.2rem',
      padding: '0.2rem',
      background: 'rgba(155, 155, 190, 0.2)',
      borderRadius: '5px',
    },
    space: {
      marginLeft: '1rem',
    },
    imageSize: {
      width: '2rem',
      borderRadius: '50%',
      border: '1px solid #ddd',
      height: '2rem',
      margin: '0',
    },
    yuanFont: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      width: '2rem',
      height: '2rem',
      textAlign: 'center',
      alignItems: 'center',
    },
  });

type TagRender = SelectProps['tagRender'];

const workModeOption = [
  {
    label: '远程',
    value: '远程',
  },
  {
    label: '现场',
    value: '现场',
  },
];
workModeOption;
const filterCityList = CITY.filter(item => {
  return (
    item.code === '1101' ||
    item.code === '3101' ||
    item.code === '4401' ||
    item.code === '4403' ||
    item.code === '3301' ||
    item.code === '5101' ||
    item.code === '4201' ||
    item.code === '4301' ||
    item.code === '3201' ||
    item.code === '5001' ||
    item.code === '3205'
  );
});

const hotCityList = filterCityList.map(item => {
  const { code, name } = item;
  return {
    code,
    name:
      code === '1101' ? '北京市' : code === '3101' ? '上海市' : code === '5001' ? '重庆市' : name,
  };
});

hotCityList.unshift({ name: '全国', code: '' });

const industryDataList = Industry.map(item => {
  const { children } = item || [];
  const name = children?.[0].children?.[0]?.label;
  const code = children?.[0].children?.[0]?.value;

  return {
    name,
    code,
  };
});

const professionalList = specialData.map(item => {
  const { children } = item || [];

  return {
    name: children[0].label,
    code: children[0].value,
  };
});

const tagRender: TagRender = props => {
  const { label, closable, onClose } = props;
  return (
    <Tag color="#9b9bbe" closable={closable} onClose={onClose} style={{ marginInlineEnd: 4 }}>
      {label}
    </Tag>
  );
};

const publicBiddingProjectsListView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const projectListReducer = useSelector((appState: AppState) => appState.projectListReducer);
  const user = useSelector((appState: AppState) => appState.user);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [projectRowData, setProjectRowData] = useState<GridRowData[]>([]);
  const [projectName, setProjectName] = useState('');
  const [cityCode, setCityCode] = useState<string>('');
  const [cityList, setCityList] = useState([...hotCityList]);
  const [open, setOpen] = useState<boolean>(false);
  const [industryCode, setIndustryCode] = useState<string>('');
  const [industryList, setIndustryList] = useState([...industryDataList]);
  const [specialCode, setSpecialCode] = useState<string>('');
  const [specialList, setSpecialList] = useState([...professionalList]);
  const [title, setTitle] = useState<string>('请选择城市');

  const [workMode, setWorkMode] = useState<string[]>(['远程', '现场']);

  const [minSalary, setMinSalary] = useState<number | null>();

  const [maxSalary, setMaxSalary] = useState<number | null>();

  const [searchMinSalary, setSearchMinSalary] = useState<number | null>();

  const [searchMaxSalary, setSearchMaxSalary] = useState<number | null>();

  const [initData, setInitData] = useState<GridRowData[]>([]);

  const listContainerRef = useRef<HTMLDivElement>(null);
  const [currentPageData, setCurrentPageData] = useState<GridRowData[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    if (projectRowData.length > 0) {
      setCurrentPageData(projectRowData.slice((newPage - 1) * pageSize, newPage * pageSize));
    }
  };

  const AreaOptions = PROVINCE.map(item => {
    const cdata = CITY.map(citem => {
      if (item.code == citem.provinceCode) {
        return { label: citem.name, value: citem.code };
      }
    });
    const r = cdata.filter(s => {
      return s != undefined;
    });
    return { label: item.name, value: item.code, children: [...r] };
  });
  AreaOptions;
  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  // };

  const setRowData = () => {
    if (projectListReducer && projectListReducer.projectList?.length > 0) {
      setProjectRowData(projectListReducer.projectList);
      setInitData(projectListReducer.projectList);
    }
  };

  const handleClickCityItem = (code: string) => {
    setCityCode(code);
  };

  const selectCity = (codeValue: string) => {
    const findIndex = cityList.findIndex(cityItem => cityItem.code === codeValue);
    if (findIndex === -1) {
      const filterItem = CITY.filter(city => city.code === codeValue);
      const { name, code } = filterItem[0];
      const addItem = {
        name: code === '1201' ? '天津市' : name,
        code: filterItem[0].code,
      };
      setCityList(prev => {
        prev[prev.length - 1] = addItem;
        return [...prev];
      });
    }
    setCityCode(codeValue);
    setOpen(false);
    setTitle('');
  };

  const selectIndustry = (item: { name: string; code: string }) => {
    const findIndex = industryList.findIndex(industryItem => industryItem.code === item.code);
    if (findIndex === -1) {
      setIndustryList(prev => {
        prev[prev.length - 1] = item;
        return [...prev];
      });
    }
    setIndustryCode(item.code);
    setOpen(false);
    setTitle('');
  };

  const selectSpecial = (item: { name: string; code: string }) => {
    const findIndex = specialList.findIndex(specialItem => specialItem.code === item.code);
    if (findIndex === -1) {
      setSpecialList(prev => {
        prev[prev.length - 1] = item;
        return [...prev];
      });
    }
    setSpecialCode(item.code);
    setOpen(false);
    setTitle('');
  };

  const onFilterData = useCallback(() => {
    if (
      cityCode ||
      industryCode ||
      specialCode ||
      workMode.length ||
      projectName ||
      minSalary ||
      maxSalary
    ) {
      const dataList = [...initData].filter(el => {
        const isArr: boolean[] = [];

        if (projectName) {
          el.projectName.indexOf(projectName) !== -1 ? isArr.push(true) : isArr.push(false);
        }

        if (cityCode) {
          const isCityCodeArr: boolean[] = [];
          cityCode === el.workLocationCity ? isCityCodeArr.push(true) : isCityCodeArr.push(false);
          isCityCodeArr.some(x => x == true) ? isArr.push(true) : isArr.push(false);
        }
        if (industryCode) {
          const industryArr1: [][] = JSON.parse(el.industry);
          if (industryArr1.length > 0) {
            const isIndustryArr: boolean[] = [];
            const newest = new Set(industryArr1);

            newest.forEach(industryArrItem => {
              industryCode == industryArrItem[2]
                ? isIndustryArr.push(true)
                : isIndustryArr.push(false);
            });

            isIndustryArr.some(x => x === true) ? isArr.push(true) : isArr.push(false);
          } else {
            isArr.push(false);
          }
        }

        if (specialCode) {
          const specialArr: [][] = JSON.parse(el.professionalClass);

          if (specialArr.length > 0) {
            const isSpecialArr: boolean[] = [];
            const newest = new Set(specialArr);

            newest.forEach(industryArrItem => {
              specialCode == industryArrItem[1]
                ? isSpecialArr.push(true)
                : isSpecialArr.push(false);
            });

            isSpecialArr.some(x => x === true) ? isArr.push(true) : isArr.push(false);
          } else {
            isArr.push(false);
          }
        }

        if (workMode.length === 2 || !workMode.length) {
          isArr.push(true);
        } else {
          if (el.workScenario) {
            const workScenario = JSON.parse(el.workScenario);

            if (workMode.includes('现场') && !workMode.includes('远程')) {
              workScenario.onsite ? isArr.push(true) : isArr.push(false);
            }
            if (workMode.includes('远程') && !workMode.includes('现场')) {
              workScenario.remote ? isArr.push(true) : isArr.push(false);
            }
          } else {
            isArr.push(false);
          }
        }
        const { remunerationInfo } = el;
        if (searchMinSalary) {
          if (remunerationInfo.otherSalaryCycle) {
            isArr.push(false);
          } else {
            const currentMin =
              remunerationInfo.singleSalaryMin || remunerationInfo.monthlySalaryMin;
            const currentMax =
              remunerationInfo.singleSalaryMax || remunerationInfo.monthlySalaryMax;
            if (!searchMaxSalary) {
              searchMinSalary <= currentMin
                ? isArr.push(true)
                : searchMinSalary <= currentMax
                ? isArr.push(true)
                : isArr.push(false);
            } else {
              searchMinSalary <= currentMin && searchMaxSalary >= currentMax
                ? isArr.push(true)
                : isArr.push(false);
            }
          }
        }
        if (searchMaxSalary) {
          if (remunerationInfo.otherSalaryCycle) {
            isArr.push(false);
          } else {
            const currentMin =
              remunerationInfo.singleSalaryMin || remunerationInfo.monthlySalaryMin;
            const currentMax =
              remunerationInfo.singleSalaryMax || remunerationInfo.monthlySalaryMax;
            if (!searchMinSalary) {
              searchMaxSalary >= currentMax
                ? isArr.push(true)
                : searchMaxSalary >= currentMin
                ? isArr.push(true)
                : isArr.push(false);
            } else {
              searchMinSalary <= currentMin && searchMaxSalary >= currentMax
                ? isArr.push(true)
                : isArr.push(false);
            }
          }
        }

        return isArr.includes(false) ? false : true;
      });
      setProjectRowData(dataList);
    } else {
      setProjectRowData(initData);
    }
  }, [cityCode, industryCode, specialCode, workMode, projectName, minSalary, maxSalary]);

  const renderWorkMode = (mode?: workScenarioSelect | undefined) => {
    if (!mode) return '现场';
    const workScenario = JSON.parse(mode);
    const keys = Object.keys(workScenario);
    const renderKeys: string[] = [];
    keys.forEach(key => {
      if (workScenario[key]) {
        renderKeys.push(key);
      }
    });
    if (!renderKeys.length) {
      return '现场';
    } else if (renderKeys.length === 1) {
      return renderKeys[0] === 'onsite' ? '现场' : '远程';
    } else {
      return '现场,远程';
    }
  };

  useEffect(() => {
    setRowData();
  }, [projectListReducer]);

  const fetchData = async () => {
    await dispatch(anonymousGetAllProjectRequest());
  };

  const handleReset = () => {
    setProjectName('');
    setCityCode('');
    setIndustryCode('');
    setSpecialCode('');
    setWorkMode(['远程', '现场']);
    setMinSalary(null);
    setMaxSalary(null);
    setSearchMinSalary(null);
    setSearchMaxSalary(null);
  };

  const viewProject = (id?: number) => {
    if (!id) return;
    const page = user.id ? 'projectPreview' : 'anyOneProjectPreview';
    navigate(`/projectRequest/${page}/#${id}`, {
      state: { row: id, bidRequest: user.userType == FUserTypeEnum.CONSULTANT ? true : false },
    });
    // setOpen(true);
  };

  const resultProfessional = (
    professionalList: string | CascadeArrayType[],
    num: number
  ): string[] => {
    const data = JSON.parse(professionalList.toString());
    const valuesToSearch = data.map((subArray: []) => subArray[subArray.length - 1]);
    const zhi = searchLabelsByValue(Professional, valuesToSearch);
    return zhi.splice(0, num);
  };

  const resultIndustryView = (industry: string | CascadeArrayType[], num: number): [] => {
    const industryObj = industry ? JSON.parse(industry.toString()) : '';
    if (industryObj) {
      const industry = industryFilterHandle(industryObj, Industry);
      const newIndustry = industry.length > 2 && industry ? industry.slice(0, num) : industry;
      return newIndustry as [];
    }
    return [];
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    onFilterData();
  }, [onFilterData]);

  useEffect(() => {
    if (projectRowData.length > 0) {
      setCurrentPageData(
        projectRowData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      );
    }
  }, [projectRowData]);

  return (
    <div className={classes.pagePadding}>
      <Helmet title="找项目"></Helmet>
      <div className={classes.container}>
        <div className={classes.filterContainer}>
          <div className={classes.filterItem}>
            <div className={classes.filterItemLabel}>城市：</div>
            <div className={classes.filterItemContent}>
              {cityList.map(item => {
                return (
                  <div
                    className={classNames(classes.cityItem, {
                      [classes.cityItemActive]: item.code === cityCode,
                    })}
                    key={item.code}
                    onClick={() => handleClickCityItem(item.code)}
                  >
                    {item.name}
                  </div>
                );
              })}
              <div
                className={classes.cityItem}
                onClick={() => {
                  setTitle('请选择城市');
                  setOpen(true);
                }}
              >
                更多城市
              </div>
            </div>
          </div>
        </div>
        <div className={classes.filterContainer}>
          <div className={classes.filterItem}>
            <div className={classes.filterItemLabel}>行业：</div>
            <div className={classes.filterItemContent}>
              {industryList.map(item => {
                return (
                  <div
                    className={classNames(classes.cityItem, {
                      [classes.cityItemActive]: item.code === industryCode,
                    })}
                    key={item.code}
                    onClick={() => setIndustryCode(item.code as string)}
                  >
                    {item.name}
                  </div>
                );
              })}
              <div
                className={classes.cityItem}
                onClick={() => {
                  setTitle('请选择行业');
                  setOpen(true);
                }}
              >
                更多行业
              </div>
            </div>
          </div>
        </div>
        <div className={classes.filterContainer}>
          <div className={classes.filterItem}>
            <div className={classes.filterItemLabel}>职能：</div>
            <div className={classes.filterItemContent}>
              {specialList.map(item => {
                return (
                  <div
                    className={classNames(classes.cityItem, {
                      [classes.cityItemActive]: item.code === specialCode,
                    })}
                    key={item.code}
                    onClick={() => setSpecialCode(item.code as string)}
                  >
                    {item.name}
                  </div>
                );
              })}
              <div
                className={classes.cityItem}
                onClick={() => {
                  setTitle('请选择职能');
                  setOpen(true);
                }}
              >
                更多职能
              </div>
            </div>
          </div>
        </div>
        <div className={classes.filterContainer}>
          <div className={classes.filterItem}>
            <div className={classes.filterItemLabel}>工作方式：</div>
            <div className={classes.filterItemContent}>
              <Select
                style={{
                  width: 200,
                }}
                tagRender={tagRender}
                options={workModeOption}
                value={workMode}
                mode="multiple"
                onChange={setWorkMode}
              />
            </div>
          </div>
        </div>
        <div className={classes.filterContainer}>
          <div className={classes.filterItem}>
            <div className={classes.filterItemLabel}>预算区间：</div>
            <div className={classes.filterItemContent}>
              <TextField
                type="number"
                variant="outlined"
                placeholder="请输入最小预算"
                className={classes.salaryInput}
                style={{
                  width: 150,
                }}
                value={minSalary === null ? '' : minSalary}
                onChange={e => {
                  const value = e.target.value;
                  setMinSalary(value ? +value : undefined);
                }}
                onBlur={e => {
                  const value = e.target.value;
                  setSearchMinSalary(value ? +value : undefined);
                }}
              />
              <TextField
                type="number"
                variant="outlined"
                placeholder="请输入最大预算"
                className={classes.salaryInput}
                style={{
                  width: 150,
                }}
                value={maxSalary === null ? '' : maxSalary}
                onChange={e => {
                  const value = e.target.value;
                  setMaxSalary(value ? +value : undefined);
                }}
                onBlur={e => {
                  const value = e.target.value;
                  setSearchMaxSalary(value ? +value : undefined);
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.filterBottom}>
          <Button
            color="primary"
            variant="contained"
            className={classes.searchButton}
            onClick={handleReset}
          >
            重置
          </Button>
        </div>
      </div>

      {currentPageData.length > 0 ? (
        <div className={classes.listContainer} ref={listContainerRef}>
          {currentPageData.map(item => {
            return (
              <div
                className={classes.listItem}
                key={item.id}
                onClick={e => {
                  e.preventDefault();
                  viewProject(item.id);
                }}
              >
                <div className={classes.topItem}>
                  <div className={classes.itemInfo}>
                    <div className={classes.infoLabel}>
                      <AssignmentIcon />
                    </div>
                    <div className={classes.projectName}>{item.projectName}</div>
                  </div>
                  <div className={classes.itemInfo}>
                    <div className={classes.infoLabel}>
                      <span className={classes.yuanFont}>¥</span>
                      <span className={classes.infoLabelValue}>预算：</span>
                    </div>
                    {item?.remunerationInfo?.monthlySalaryMin ? (
                      <div className={classes.infoValue}>
                        {item?.remunerationInfo?.monthlySalaryMin}
                        {item?.remunerationInfo?.monthlySalaryMax && '-'}
                        {item?.remunerationInfo?.monthlySalaryMax}
                      </div>
                    ) : item?.remunerationInfo?.singleSalaryMin ? (
                      <div className={classes.infoValue}>
                        {item?.remunerationInfo.singleSalaryMin}
                        {item?.remunerationInfo.singleSalaryMax && '-'}
                        {item?.remunerationInfo.singleSalaryMax}
                      </div>
                    ) : (
                      <div className={classes.infoValue}>
                        {item?.remunerationInfo?.otherSalaryDescription ? '面议' : null}
                      </div>
                    )}
                  </div>

                  <div className={classes.itemInfoLine}>
                    {item.industry &&
                      resultIndustryView(item.industry, 10).map((item, index) => {
                        return (
                          <span className={classes.textTag} key={index}>
                            {item}
                          </span>
                        );
                      })}
                    {item.professionalClass &&
                      resultProfessional(item.professionalClass, 10).map((item, index) => {
                        return (
                          <span className={classes.textTag} key={index}>
                            {item}
                          </span>
                        );
                      })}
                    {item.label &&
                      JSON.parse(item.label).map((Item: string, index: number) => {
                        return (
                          <span className={classes.textTag} key={index}>
                            {Item}
                          </span>
                        );
                      })}
                    <span className={classes.textTag}>
                      {Seniority.find(option => option.value == item.seniority)?.label}经验
                    </span>
                  </div>
                  <div className={classes.itemInfo}>
                    <div className={classes.infoLabel}>
                      <LocationOnIcon />
                      <span className={classes.infoLabelValue}>地址：</span>
                      {item.workLocationAddress}
                      <span className={classes.space}>{renderWorkMode(item.workScenario)} </span>
                    </div>
                  </div>
                  <div className={classes.itemInfo}>
                    <img
                      src={
                        item.company.verification.basicInfo.avatar
                          ? item.company.verification.basicInfo.avatar
                          : picture
                      }
                      className={classes.imageSize}
                    />

                    <span className={classes.infoLabelValue}>
                      {item.company.verification.basicInfo.registrationName}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={classes.empty}>
          <h2 className={classes.textTitle}>加载中...</h2>
        </div>
      )}

      <div className={classes.paginationRight}>
        <Pagination
          totalItems={projectRowData.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>

      {/* <Dialog onClose={handleDialogClose} open={dialogOpen} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          申请项目
        </DialogTitle>
        <DialogContent dividers>
          <ConsultantAppliesBiddingView
            editData={true}
            projectDataId={projectId}
            onClose={handleDialogClose}
          ></ConsultantAppliesBiddingView>
        </DialogContent>
      </Dialog> */}
      <CancelCompanyPublishProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
        }}
      ></CancelCompanyPublishProjectDialog>

      <Modal
        open={open}
        title={title}
        footer={null}
        width={800}
        centered
        onCancel={() => setOpen(false)}
      >
        {title === '请选择城市' ? (
          <ConsultantCityListView selectCity={selectCity} />
        ) : title === '请选择行业' ? (
          <ConsultantIndustryView selectIndustry={selectIndustry} />
        ) : (
          <ConsultantSpecialView selectSpecial={selectSpecial} />
        )}
      </Modal>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(publicBiddingProjectsListView);
